/* eslint-disable no-underscore-dangle */
import { rest } from 'msw';
import ENGAGEMENT_API from '../../services/engagementService/constants';
import { StartEngagementDTO } from '../../services/engagementService/types';
import {
  checkIsCredentialsValid,
  checkIsStartEngengementPropsValid,
  checkRequiredProps,
  RestRequestWithAuthorization,
} from '../helpers';

type StartEngagementRequiredProps = keyof StartEngagementDTO;

export const startEngagementHandler = rest.post(
  `${ENGAGEMENT_API.url}/chats/:chat_id/start`,
  async (req, res, ctx) => {
    const { isValid, errors: credentialsErrors } = checkIsCredentialsValid(
      req as RestRequestWithAuthorization,
    );

    if (!isValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: credentialsErrors,
        }),
      );
    }

    const { isValid: isDynamicPropsValid, errors: dynamicPropsErrors } =
      checkIsStartEngengementPropsValid(req as RestRequestWithAuthorization);

    if (!isDynamicPropsValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: dynamicPropsErrors,
        }),
      );
    }

    const requiredProps: StartEngagementRequiredProps[] = [
      'user_id',
      'app_id',
      'consumer',
      'gateway',
      'version',
      'stream_id',
    ];
    const { isError, errors } = await checkRequiredProps(requiredProps, req.body);

    if (isError) {
      return res(
        ctx.status(422),
        ctx.json({
          errors,
        }),
      );
    }

    return res(ctx.status(202));
  },
);

export default startEngagementHandler;
