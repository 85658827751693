import { setupWorker } from 'msw';
import { setupServer } from 'msw/node';
import handlers from './handlers';

const startMock = () => {
  if (typeof window === 'undefined') {
    const worker = setupServer(...handlers);
    worker.listen();
  } else {
    const worker = setupWorker(...handlers);
    worker.start();
  }
};

export default startMock;
