import * as URL from '../../env';

const ENGAGEMENT_API = {
  url: {
    live: URL.ENGAGEMENT_API_LIVE,
    sandbox: URL.ENGAGEMENT_API_SABDBOX,
  },
  socketUrl: {
    live: URL.WEB_SOCKET_URL_LIVE,
    sandbox: URL.WEB_SOCKET_URL_SABDBOX,
  },
  endpoints: {
    createUser: '/consumers/anonymous_session',
    importApp: (appId: string) => `/microapps/${appId}/import`,
    start: (chatId: string) => `/chats/${chatId}/start`,
    message: (chatId: string) => `/chats/${chatId}/message`,
    end: (chatId: string) => `/chats/${chatId}/end`,
    logo: (appId: string) => `/pd${appId}`,
  },
};

export default ENGAGEMENT_API;
