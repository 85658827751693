import { rest } from 'msw';
import ENGAGEMENT_API from '../../services/engagementService/constants';
import { CreateUserDTO } from '../../services/engagementService/types';
import MOCK from '../constants';
import { checkRequiredProps } from '../helpers';

type CreateUserRequiredProps = keyof CreateUserDTO;

export const createUserHandler = rest.post(
  `${ENGAGEMENT_API.url}${ENGAGEMENT_API.endpoints.createUser}`,
  async (req, res, ctx) => {
    const requiredProps: CreateUserRequiredProps[] = [
      'app_id',
      'browser_language',
      'referring_site',
      'user_browser',
    ];
    const { isError, errors } = await checkRequiredProps(requiredProps, req.body);

    if (isError) {
      return res(
        ctx.status(400),
        ctx.json({
          errors,
        }),
      );
    }

    return res(
      ctx.status(201),
      ctx.json({
        access_token: MOCK.token,
        apn_token: null,
        avatar: null,
        avatar_generated: false,
        chat_id: MOCK.chatId,
        created_at: 1649847966,
        data_version: 2,
        email: null,
        first_name: null,
        gcm_token: null,
        id: MOCK.userId,
        last_name: 'Visitor',
        location: null,
        masking_rules: [],
        phone: null,
        pype_logo: null,
        role: 'consumer',
        sneekpeak: false,
        web_chat_pype_id: MOCK.webChatPypeId,
        web_chat_pype_name: 'MapiSandboxPype',
        web_chat_stream_id: MOCK.webChatStreamId,
        widget_auto_start_with_bot: false,
        widget_branding: null,
        widget_data: {
          created_by: null,
          custom_pype_css: null,
          enable_prechat: false,
          id: 'fb63443b-9035-4106-a743-6b7fab41abd4',
          inserted_at: '2022-03-24T10:16:03',
          prechat_custom_field1_name: null,
          prechat_custom_field1_value: null,
          prechat_custom_field2_name: null,
          prechat_custom_field2_value: null,
          prechat_email: null,
          prechat_firstname: null,
          prechat_lastname: null,
          prechat_msg: null,
          prechat_phone: null,
          pype_id: '95355c97-ea75-4022-98d2-0a7084428cf5_6f02e208-ee7f-4bf7-9452-2fc7efadb4b9',
          stream_id: 'b1af5aff-47a3-429e-afcb-435b2ba2d690',
          style: null,
          updated_at: '2022-03-24T10:16:03',
          whitelist: null,
          widget_name: 'MapiInterface',
        },
        widget_gateway_id: null,
        widget_version: null,
      }),
    );
  },
);

export default createUserHandler;
