const declOfNum = (number: number, word: string): string => (number > 1 ? `${word}s` : word);

const getLabel = (value: number, word: string) =>
  value ? `${value} ${declOfNum(value, word)}` : '';

const getSecondsLabel = (seconds: number): string => getLabel(seconds, 'second');

const getMinutesLabel = (minutes: number): string => getLabel(minutes, 'minute');

const getHoursLabel = (hours: number): string => getLabel(hours, 'hour');

export const formatSeconds = (value: number) => {
  let secondTime = value;
  let minuteTime = 0;
  let hourTime = 0;
  const secInMinute = 60;

  if (secondTime >= secInMinute) {
    minuteTime = Math.floor(secondTime / secInMinute);
    secondTime %= secInMinute;

    if (minuteTime >= secInMinute) {
      hourTime = Math.floor(minuteTime / secInMinute);
      minuteTime %= secInMinute;
    }
  }

  let result = getSecondsLabel(secondTime);

  if (minuteTime > 0) {
    result = `${getMinutesLabel(minuteTime)} ${result}`;
  }

  if (hourTime > 0) {
    result = `${getHoursLabel(hourTime)} ${result}`;
  }

  return result.trim();
};
