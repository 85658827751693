/* eslint-disable no-underscore-dangle */
import { rest } from 'msw';
import ENGAGEMENT_API from '../../services/engagementService/constants';
import { EndEngagementDTO } from '../../services/engagementService/types';
import {
  checkIsCredentialsValid,
  checkIsEndEngengementPropsValid,
  checkRequiredProps,
  RestRequestWithAuthorization,
} from '../helpers';

type EndEngagementRequiredProps = keyof EndEngagementDTO;

export const endEngagementHandler = rest.post(
  `${ENGAGEMENT_API.url}/chats/:chat_id/end`,
  async (req, res, ctx) => {
    const { isValid, errors: credentialsErrors } = checkIsCredentialsValid(
      req as RestRequestWithAuthorization,
    );

    if (!isValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: credentialsErrors,
        }),
      );
    }

    const { isValid: isDynamicPropsValid, errors: dynamicPropsErros } =
      checkIsEndEngengementPropsValid(req as RestRequestWithAuthorization);

    if (!isDynamicPropsValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: dynamicPropsErros,
        }),
      );
    }

    const requiredProps: EndEngagementRequiredProps[] = [
      'ended_by',
      'pype_id',
      'source',
      'user_id',
      'version',
    ];
    const { isError, errors } = await checkRequiredProps(requiredProps, req.body);

    if (isError) {
      return res(
        ctx.status(422),
        ctx.json({
          errors,
        }),
      );
    }

    return res(ctx.status(204));
  },
);

export default endEngagementHandler;
