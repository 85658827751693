import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { ComponentType, FC, useEffect } from 'react';
import { ErrorBoundary } from '../src/components/combined/ErrorBoundary';
import { IS_MOCK, LAUNCHDARKLY_CLIENT_ID } from '../src/env';
import { isEmpty } from '../src/helpers';
import startMock from '../src/mocks';
import { WindowWithFeatureFlags } from '../src/types/globals';
import '../src/styles/styles.scss';

if (IS_MOCK) {
  startMock();
}

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const flags = useFlags();

  useEffect(() => {
    if (isEmpty(flags)) {
      return;
    }

    (window as WindowWithFeatureFlags).featureFlags = flags;
  }, [flags]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  );
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
})(App as ComponentType<{}>);
