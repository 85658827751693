/* eslint-disable no-underscore-dangle */
import { rest } from 'msw';
import ENGAGEMENT_API from '../../services/engagementService/constants';
import { SendMessageDTO } from '../../services/engagementService/types';
import {
  checkIsCredentialsValid,
  checkIsSendMessagePropsValid,
  checkRequiredProps,
  RestRequestWithAuthorization,
} from '../helpers';

type SendMessageRequiredProps = keyof SendMessageDTO;

export const sendMessageHandler = rest.post(
  `${ENGAGEMENT_API.url}/chats/:chat_id/message`,
  async (req, res, ctx) => {
    const { isValid, errors: credentialsErrors } = checkIsCredentialsValid(
      req as RestRequestWithAuthorization,
    );

    if (!isValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: credentialsErrors,
        }),
      );
    }

    const { isValid: isDynamicPropsValid, errors: dynamicPropsErros } =
      checkIsSendMessagePropsValid(req as RestRequestWithAuthorization);

    if (!isDynamicPropsValid) {
      return res(
        ctx.status(401),
        ctx.json({
          errors: dynamicPropsErros,
        }),
      );
    }

    const requiredProps: SendMessageRequiredProps[] = [
      'from',
      'from_side',
      'gateway',
      'msg',
      'msg_type',
      'user_id',
      'version',
    ];
    const { isError, errors } = await checkRequiredProps(requiredProps, req.body);

    if (isError) {
      return res(
        ctx.status(422),
        ctx.json({
          errors,
        }),
      );
    }

    return res(ctx.status(204));
  },
);

export default sendMessageHandler;
