/* eslint-disable no-underscore-dangle */
import { DefaultRequestBody, PathParams, RestRequest } from 'msw';
import {
  EndEngagementDTO,
  SendMessageDTO,
  StartEngagementDTO,
} from '../services/engagementService/types';
import MOCK from './constants';

export type RestRequestWithAuthorization = RestRequest<DefaultRequestBody, PathParams> & {
  headers?: { headers: { authorization?: string } };
};

interface CheckRequiredProps {
  (requiredProps: string[], requestBody: DefaultRequestBody): Promise<{
    isError: boolean;
    errors: Record<string, unknown>;
  }>;
}

export const checkRequiredProps: CheckRequiredProps = (props, requestBody) =>
  new Promise((resolve) => {
    const errors: Record<string, unknown> = {};
    props.forEach((prop) => {
      const isPropExistInRequest = Object.keys(requestBody || {}).includes(prop);

      if (!isPropExistInRequest) {
        errors[prop] = ["can't be blank"];
      }
    });

    resolve({
      isError: Boolean(Object.keys(errors).length),
      errors,
    });
  });

export const checkIsCredentialsValid = (req: RestRequestWithAuthorization) => {
  // @ts-ignore
  const token = req?.headers?.headers.authorization;
  const { chat_id: chatId } = req.params;
  const isIdsEqual = chatId === MOCK.chatId;
  const defaultToken = `Bearer ${MOCK.token}`;
  const isTokensEqual = token === defaultToken;
  const errors = isTokensEqual
    ? {}
    : {
        detail: 'Unauthorized',
      };

  return {
    isValid: isTokensEqual && isIdsEqual,
    errors,
  };
};

export const checkIsStartEngengementPropsValid = (req: RestRequestWithAuthorization) => {
  const { stream_id: streamId, user_id: userId } = req.body as StartEngagementDTO;
  const isDynamicPropsValid = streamId === MOCK.webChatStreamId && userId === MOCK.userId;
  const errors = isDynamicPropsValid
    ? {}
    : {
        detail: 'Unauthorized',
      };

  return {
    isValid: isDynamicPropsValid,
    errors,
  };
};

export const checkIsSendMessagePropsValid = (req: RestRequestWithAuthorization) => {
  const { user_id: userId, from } = req.body as SendMessageDTO;
  const isDynamicPropsValid = userId === MOCK.userId && from === MOCK.userId;
  const errors = isDynamicPropsValid
    ? {}
    : {
        detail: 'Unauthorized',
      };

  return {
    isValid: isDynamicPropsValid,
    errors,
  };
};

export const checkIsEndEngengementPropsValid = (req: RestRequestWithAuthorization) => {
  const { user_id: userId, pype_id: pypeId } = req.body as EndEngagementDTO;
  const isDynamicPropsValid = userId === MOCK.userId && pypeId === MOCK.webChatPypeId;
  const errors = isDynamicPropsValid
    ? {}
    : {
        detail: 'Unauthorized',
      };

  return {
    isValid: isDynamicPropsValid,
    errors,
  };
};
