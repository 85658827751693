export const FISHERCAT_API_URL = String(process.env.NEXT_PUBLIC_FISHERCAT_API_URL);

export const ENGAGEMENT_API_LIVE = String(process.env.NEXT_PUBLIC_ENGAGEMENT_API_URL_LIVE);
export const ENGAGEMENT_API_SABDBOX = String(process.env.NEXT_PUBLIC_ENGAGEMENT_API_URL_SANDBOX);

export const WEB_SOCKET_URL_SABDBOX = String(process.env.NEXT_PUBLIC_WEBSOCKET_URL_SANDBOX);
export const WEB_SOCKET_URL_LIVE = String(process.env.NEXT_PUBLIC_WEBSOCKET_URL_LIVE);

export const S3_API_URL_LIVE = String(process.env.NEXT_PUBLIC_S3_END_POINT_LIVE);
export const S3_API_URL_SANDBOX = String(process.env.NEXT_PUBLIC_S3_END_POINT_SANDBOX);

export const LAUNCHDARKLY_CLIENT_ID = String(process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID);
export const LAUNCHDARKLY_ENV = String(process.env.NEXT_PUBLIC_LAUNCHDARKLY_ENV);

export const NODE_ENV = String(process.env.NODE_ENV);
export const IS_DEV_MODE = NODE_ENV === 'development';
export const IS_MOCK = String(process.env.NEXT_PUBLIC_MOCK) === 'true' && IS_DEV_MODE;
