import { IS_DEV_MODE } from '../env';

/* eslint-disable no-console */
export const logger = (value: unknown) => {
  if (IS_DEV_MODE) {
    console.log(value);
  }
};

export default logger;
