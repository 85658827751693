import React, { ErrorInfo } from "react";
import * as Sentry from '@sentry/nextjs';
import { Button, Layout } from "@pypestream/fishercat-ui";
import { logger } from "../../../helpers";

interface ErrorBoundaryProps {
    children: JSX.Element;
    fallbackComponent?: JSX.Element;
}

interface ErrorBoundaryState {
    error: Error | null;
    id?: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props)

      this.state = {
        error: null
      }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      logger({ error, errorInfo });

      this.setState({
        id: Sentry.lastEventId(),
        error
      });
    }

    private resetError = () => {
      this.setState({ error: null });
      window.location.reload();
    }

    render() {
        const { error, id } = this.state;
        const { children, fallbackComponent } = this.props;

        const exceptionInfo = (
          <>
            <h5>
              Exception information:
            </h5>
            <p>
              <i>
                description: <span style={{ textDecoration: 'underline' }}>{error?.message}</span>
                {id && (
                  <>
                    <br />
                    id: <strong>{id}</strong>
                  </>
                )}
              </i>
            </p>
          </>
        );

        const fallBack = fallbackComponent || (
            <Layout>
              <div style={{ marginBottom: 30 }}>
                <h1 style={{ marginBottom: 15 }}>
                  Oops, something happened!
                </h1>
                <p style={{ marginBottom: 15, fontSize: 16 }}>
                  It’s not you, it’s us! We apologize for the inconvenience.
                </p>
                {exceptionInfo}
              </div>
                <Button
                    type="button"
                    onClick={this.resetError}
                >
                    Reload page
                </Button>
            </Layout>
        );

        if (error) {
            return fallBack;
        }

      return children;
    }
}

  export default ErrorBoundary
